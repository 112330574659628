import { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";

const Recent = () => {
  const startDate = useMemo(() => new Date(2023, 7, 13, 20, 0, 0), []);
  const [timeDiff, setTimeDiff] = useState(startDate.getTime() - Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDiff(Date.now() - startDate.getTime());
    }, 1000);
    return () => clearInterval(interval);
  }, [startDate]);

  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
  const seconds = Math.floor((timeDiff / 1000) % 60);
  return (
    <>
      {timeDiff > 0 ? (
        <p>
          {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
        </p>
      ) : (
        <small>Calculating...</small>
      )}
    </>
  );
};

const AllTime = () => {
  const startDate = useMemo(() => new Date(2016, 1, 16, 20, 0, 0), []);
  const [timeDiff, setTimeDiff] = useState(startDate.getTime() - Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDiff(Date.now() - startDate.getTime());
    }, 1000);
    return () => clearInterval(interval);
  }, [startDate]);

  const years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor((timeDiff / (1000 * 60 * 60 * 24 * 30)) % 12);
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
  const seconds = Math.floor((timeDiff / 1000) % 60);
  return (
    <>
      {timeDiff > 0 ? (
        <div>
          <p
            style={{
              textAlign: "center",
            }}>
            {years} years, {months} months
          </p>
          <p>
            {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
          </p>
        </div>
      ) : (
        <small>Calculating...</small>
      )}
    </>
  );
};

const App = () => {
  const [mode, setMode] = useState<"allTime" | "recent">("recent");

  return (
    <div className="container">
      <nav>
        <img
          src="/logo.png"
          height={75}
          width={75}
          alt="loader"
          onClick={() => {
            setMode(mode === "allTime" ? "recent" : "allTime");
          }}
          style={{
            cursor: "pointer",
          }}
        />
      </nav>
      <motion.div
        className="overlay"
        initial={{ opacity: 1 }}
        animate={{ opacity: 0, display: "none" }}
        transition={{ duration: 1, delay: 3 }}>
        <motion.img
          src="/logo-light.png"
          height={100}
          width={100}
          alt="loader"
          animate={{
            scale: [1, 1, 1.25, 1, 1, 1.25, 1, 1, 1.25, 1, 1, 1.25, 1],
            transition: { duration: 4 },
          }}
        />
      </motion.div>
      <h1>:)</h1>
      {
        {
          allTime: <AllTime />,
          recent: <Recent />,
        }[mode]
      }
    </div>
  );
};

export default App;
